<template>
	<standard-container menu-id="admin.modificapunti" admin disable-admin-prefix>
		<!-- body slot -->
		<div>
			<v-row class="mt-2">
				<v-col md="6">
					<div class="d-block d-md-flex align-stretch">
						<v-text-field v-model="cardCode" focus label="Cerca card" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" clearable @keyup.enter="search" @click:clear="clearCard" />
						<v-btn :disabled="!cardCode" color="primary" outlined class="ml-0 ml-md-5 mt-md-0" x-large :loading="loading" @click="search">CERCA</v-btn>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="cardDataInvalid && !loading">
				<v-col><v-alert type="error" text>Numero della card o del telaio non validi</v-alert></v-col>
			</v-row>

			<v-row v-if="cardDataValid">
				<v-col cols="12">
					<v-card color="tableheader">
						<v-card-title>Dati card</v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>ID-Card</th><th>Telaio</th><th>Stato</th><th>Data Attivazione</th><th>Data Annullamento</th><th>Totale Punti</th><th>% Sconto</th><th>Nuova Card</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{ cardData.CardExt.SuperCard.IdCard }}</td>
											<td>{{ cardData.CardExt.SuperCard.TipoCard }}</td>
											<td>{{ cardData.CardExt.Card.CodTelaio }}</td>
											<td>{{ cardData.CardExt.SuperCard.Attivazione | shortDate }}</td>
											<td>{{ cardData.CardExt.SuperCard.Cessazione | shortDate }}</td>
											<td>{{ cardData.CardExt.TotalePunti }}</td>
											<td>{{ cardData.CardExt.Sconto }}</td>
											<td>{{ cardData.CardExt.SuperCard.NewCard }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row v-if="cardDataValid && cardData.CardExt.VisualizzaPassaggi">
				<v-col cols="12">
					<v-card color="tableheader">
						<v-card-title>Storico attività</v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>Organizzato</th><th>Data</th><th>Descrizione</th><th>TotFattura</th><th>Punti</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, idx) in cardData.CardExt.Passaggi" :key="idx">
											<th>{{ item.Organizzato !== "---" ? item.Organizzato : item.CodOrganizzato }}</th>
											<td>{{ item.DataDocumento | shortDate }}</td>
											<td>{{ item.Descrizione }}</td>
											<td>{{ item.ImportoNum | toCurrency }}</td>
											<td>{{ item.SaldoPunti }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row v-if="cardDataValid && cardData.CardExt.VisualizzaPassaggi">
				<v-col cols="12" class="text-right">
					<v-btn color="primary" @click="openAddPunti">Aggiungi punti</v-btn>
				</v-col>
			</v-row>





			<debug-panel label="cardData" class="mt-6">
				{{ cardData }}
			</debug-panel>

			<v-dialog v-model="formVisible" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs'" transition="scale-transition" max-width="1000" persistent @keydown.esc="formVisible = false">
				<v-card>
					<v-toolbar dense :color="appSetup.appColor" dark>
						<v-btn icon @click="formVisible = false"><v-icon>mdi-close</v-icon></v-btn>
						<v-toolbar-title>Aggiungi punti</v-toolbar-title>
					</v-toolbar>
					<v-card-text>
						<v-row v-if="loading"><v-col class="text-center"><v-progress-circular indeterminate color="primary"></v-progress-circular></v-col></v-row>					
						<v-row class="mt-4">
							<v-col md="6">
								<v-select v-model="puntiToAdd.Descrizione" label="Descrizione" :items="lavori" item-text="descrizione" item-value="codlavoro" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
							</v-col>
							<v-col md="6">
								<hr-date-picker v-model="puntiToAdd.Data" :show-current="true" label="Data" persistent-hint clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></hr-date-picker>
							</v-col>
							<v-col md="6">
								<v-text-field v-model="puntiToAdd.CodiceOrganizzato" label="Cod. Organizzato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
							</v-col>
							<v-col md="6">
								<v-text-field v-model="puntiToAdd.Punti" label="Punti" type="number" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />	
							</v-col>
						</v-row>
						<errors-container :errors="errors"></errors-container>
						<debug-panel label="puntiToAdd">
							{{ puntiToAdd }}
						</debug-panel>
					</v-card-text>
					<v-card-actions class="d-flex justify-space-between">
						<v-btn class="ma-6" large outlined @click="formVisible = false">Chiudi</v-btn>
						<v-btn class="ma-6" large outlined color="primary" :loading="loading" @click="addPunti">Aggiungi</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** ADMIN MODIFICA PUNTI ***************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import ErrorsContainer from '@/components/errors-container'
import StandardContainer from '@/components/standard-container'
import HrDatePicker from '@/components/hr-date-picker'
export default {
	name: 'AdminCardClientiTelaiPage',
	components: { StandardContainer, DebugPanel, ErrorsContainer,HrDatePicker },
	data: () => {
		return {
			loading: false,
			cardCode: '',
			errors: [],
			cardData: null,
			formVisible: false,
			lavori: [],
			puntiToAdd: {
				CodTelaio: null,
				Descrizione: '',
				CodiceOrganizzato: '',
				Data: null,
				Punti: 0
			}
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		darkTheme() { return this.$vuetify.theme.dark },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		cardDataInvalid() { return !!this.cardCode && !!this.cardData && !this.cardData.IsValid },
		cardDataValid() { return !!this.cardData && !!this.cardData.IsValid },
	},
	created: function () {
		if (this.appSetup.isDevelop) {
		}
	},
	methods: {
		search() {
			if(this.cardCode) {
				this.cardData = null
				this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/card/verify', value: {Code: this.cardCode}}).then(result => {
					this.cardData = result 
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		clearCard(){
			this.cardData = null
		},	
		openAddPunti() {
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/punti/lavori'}).then(result => {
				this.lavori = result 
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			})
			this.puntiToAdd = {
				CodTelaio: this.cardData.CardExt.Card.CodTelaio,
				Descrizione: '',
				CodiceOrganizzato: '',
				Data: null,
				Punti: 0
			}
			this.formVisible=true
		},
		addPunti() {
			this.formVisible=false
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/admin/punti/add', value: this.puntiToAdd}).then(result => {
				setTimeout(() => {this.search()}, 500) 
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
	},
}
</script>

<style scoped lang="less">

</style>