<template>
	<div>
		<v-menu
			v-model="menu1"
			:close-on-content-click="false"
			max-width="290"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					:value="computedDateFormattedMomentjs"
					:clearable="clearable"
					:label="label"
					readonly
					:filled="filled"
					:solo="solo"
					:dense="dense"
					:outlined="outlined"
					:hide-details="hideDetails"
					:disabled="disabled"
					:hint="hint"
					:persistent-hint="persistentHint"
					v-on="on"
					@click:clear="clearDate"
				/>
			</template>
			<v-date-picker
				v-model="date"
				locale="it-it"
				:first-day-of-week="1"
				reactive
				:min="min"
				:max="max"
				:type="monthOnly ? 'month': 'date'"
				:show-current="showCurrent"
				:picker-date.sync="startPickerDate"
			/>
		</v-menu>
		<div v-if="viewDebugElements">
			<code>value: {{ value }}</code><br>
			<code class="mt-1">date: {{ date }}</code>
			<code v-if="min" class="mt-1">min: {{ min }}</code>
			<code v-if="max" class="mt-1">max: {{ max }}</code>
			<code v-if="startPickerDate" class="mt-1">pickerDate: {{ startPickerDate }}</code>
		</div>
	</div>
</template>

<script>
/**********************************************************************************************
**** COMPONETE DATE PICKER STANDARD ***********************************************************
***********************************************************************************************
* personalizzazione del sistema date-picker di vuetify per semplificare il codice e uniformare la gestione delle date
*/
import moment from 'moment'
export default {
	name: 'HrDatePicker',
	props: {
		value: {
			type: String,
			default: () => {return null}
		},
		label :{
			type: String,
			default: () => {return 'Data'}
		},
		solo: {
			type: Boolean,
			default: () => {return false}
		},
		filled: {
			type: Boolean,
			default: () => {return false}
		},
		dense: {
			type: Boolean,
			default: () => {return false}
		},
		outlined: {
			type: Boolean,
			default: () => {return false}
		},
		hideDetails: {
			type: Boolean,
			default: () => {return false}
		},
		disabled: {
			type: Boolean,
			default: () => {return false}
		},
		clearable: {
			type: Boolean,
			default: () => {return false}
		},
		// se true visualizza le date con il mese accorciato a tre caratteri
		shortDate: {
			type: Boolean,
			default: () => {return false}
		},
		min: {
			type: String,
			default: () => {return undefined}
		},
		max: {
			type: String,
			default: () => {return undefined}
		},
		// se true il clear (abilitato con clearable) porta il value a null
		// se false invece usa la data corrente (default = true)
		clearableAtNull: {
			type: Boolean,
			default: () => {return true}
		},
		// nuovi campi aggiunti rispetto ad hr in data aprile 2021
		hint: {
			type: String,
			default: () => {return ''}
		},
		persistentHint: {
			type: Boolean,
			default: () => {return false}
		},
		// se true lavora solo con i mesi
		monthOnly: {
			type: Boolean,
			default: () => {return false}
		},
		// evidenzia la data corrente
		showCurrent: {
			type: Boolean,
			default: () => {return true}
		},
		// se impostata forza il calendario ad aprirsi su quella data invece che sulla data corrente (vale solo se non è gia stata selezionata una data, nel qual caso si apre quella normalmente)
		pickerDate: {
			type: String,
			default: () => {return undefined}
		},
	},
	data: () => {
		return {
			menu1: false,
			startPickerDate: undefined,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		computedDateFormattedMomentjs () {
			moment.locale('it')
			return this.date ? moment(this.date).format(this.shortDate ? (this.monthOnly ? 'MMM YYYY' : 'DD MMM YYYY') : (this.monthOnly ? 'MMMM YYYY' : 'DD MMMM YYYY')) : ''
		},
		pickerDateFormatted() {
			return this.pickerDate ? moment(this.pickerDate).format('YYYY-MM') : this.pickerDate
		},
		date: {
			get: function () {
				return this.value ? moment(this.value).format(this.monthOnly ? 'YYYY-MM' : 'YYYY-MM-DD') : null
			},
			set: function (newValue) {
				var oldValue = this.value
				this.menu1 = false
				this.$emit('input', newValue? moment(newValue).format('YYYY-MM-DD') + 'T00:00:00' : newValue)
				this.$emit('change', {oldValue: oldValue, newValue: newValue}) 
			}
		},
	},
	watch:{
		// si attiva all'apertura del calendario
		menu1(to, from) {
			if(from==false && to==true && this.pickerDate && !this.date) {
				// imposta la picker date (ovvero l'anno o il mese da cui far partire il calendario) solo se la data è nulla (e ovviamente è impostata la relativa proprietà picker-date)
				this.startPickerDate = moment(this.pickerDate).format(this.monthOnly ? 'YYYY' : 'YYYY-MM')
			}
		}
	},		
	created() {
		moment.locale('it')
	},
	methods: {
		clearDate() {
			this.date = this.clearableAtNull ? null : moment().format('YYYY-MM-DD')
		}
	}	
}
</script>

<style scoped lang="less">

</style>